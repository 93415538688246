import { Show, createMemo } from 'solid-js';
import { Link, Picture } from '@troon/ui';
import { gql } from '../../../../graphql';
import { createFragment } from '../../../../graphql/create-fragment';
import type { FragmentType, PopularPlaceFragment, PopularRegionFragment } from '../../../../graphql';

type Props = (FragmentType<typeof PopularRegionFragment> | FragmentType<typeof PopularPlaceFragment>) & {
	pathname?: string;
};

export function PopularDestination(props: Props) {
	const item = createItem(props);

	return (
		<div class="group relative overflow-hidden rounded border border-neutral">
			<div
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class="relative aspect-[2/1] overflow-hidden"
			>
				<Show when={item().hero}>
					{(url) => (
						<Picture
							src={url()}
							alt=""
							width={346}
							height={173}
							sizes="(min-width: 1280px) 25vw, (min-width: 1024px) 33vw, 50vw"
							class="absolute inset-0 overflow-hidden bg-center object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
							loading="lazy"
						/>
					)}
				</Show>
			</div>
			<p class="flex flex-col gap-px px-4 py-2">
				<Link href={item().url} class="font-semibold after:absolute after:inset-0 hover:text-brand-600">
					{item().name}
				</Link>
				<span class="text-sm font-medium text-neutral-800">{item().facilityCount} courses</span>
			</p>
		</div>
	);
}

export function PopularDestinationFullBleed(props: Props) {
	const item = createItem(props);

	return (
		<div class="group relative flex aspect-video items-end overflow-hidden rounded text-white active:scale-95 motion-safe:transition-transform md:aspect-square">
			<Show when={item().hero}>
				{(url) => (
					<Picture
						src={url()}
						alt=""
						width={400}
						height={400}
						mode="crop"
						sizes="(min-width: 1280px) 25vw, (min-width: 1024px) 33vw, 50vw"
						class="absolute inset-0 aspect-square overflow-hidden bg-center object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
						loading="lazy"
					/>
				)}
			</Show>
			<p class="z-10 flex flex-col gap-px px-4 pb-4">
				<Link href={item().url} class="text-lg font-semibold after:absolute after:inset-0">
					{item().name}
				</Link>
				<span class="font-medium">{item().facilityCount} courses</span>
			</p>
		</div>
	);
}

const PopularRegionFragment = gql(`fragment PopularRegion on DestinationRegion {
  region {
    id
	  name
	  hero { url }
	  facilityCount
  }
}`);
const PopularPlaceFragment = gql(`fragment PopularPlace on DestinationPlace {
  place {
    name
    regionName
    coordinates {
      latitude
      longitude
    }
		hero { url }
    facilityCount
  }
}`);

function createItem(props: Props) {
	const item =
		'region' in props
			? createFragment(PopularRegionFragment, props as FragmentType<typeof PopularRegionFragment>)
			: createFragment(PopularPlaceFragment, props as FragmentType<typeof PopularPlaceFragment>);

	const normalized = createMemo(() => {
		if ('region' in item) {
			return {
				hero: item.region.hero?.url,
				url: `${props.pathname ?? '/courses/search'}?regionId=${item.region.id}&query=${item.region.name}`,
				name: item.region.name,
				facilityCount: item.region.facilityCount,
			};
		}

		const query = new URLSearchParams({
			lat: item.place.coordinates.latitude.toString(),
			lon: item.place.coordinates.longitude.toString(),
			query: `${item.place.name}, ${item.place.regionName}`,
		});
		return {
			hero: item.place.hero?.url,
			url: `${props.pathname ?? '/courses/search'}?${query.toString()}`,
			name: `${item.place.name}, ${item.place.regionName}`,
			facilityCount: item.place.facilityCount,
		};
	});
	return normalized;
}
